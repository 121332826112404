body {
  margin: 0;
  font-weight: 500;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eef0f3;
  color: #626a75;
}

html {
  overflow-y: scroll;
}

* {
  line-height: 1;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

button,
select,
input {
  outline: none;
  font-size: 0.875rem;
  font-family: Poppins;
}

body .Toastify__toast {
  font-family: "Poppins";
  padding: 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

body .Toastify__toast--success {
  background: #3bbe80;
}

body .Toastify__close-button {
  align-self: center;
}

body .tippy-popper [data-animation="shift"].enter {
  transform: translateY(0);
}

body .tippy-tooltip {
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.1);
}

body .tippy-popper[x-placement^="top"] [x-arrow] {
  border-color: white;
}
